<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light">KEGIATAN HARIAN</p>
          <router-link
            v-if="isPSPD"
            :to="{ name: 'kegiatan-mahasiswa-add' }"
            class="card-header-icon has-text-light"
          >
            <b-icon class="has-text-light" size="is-small" icon="plus" />
          </router-link>
          <!-- <a
            v-if="canVerifikasi && kegiatanLength > 0"
            class="card-header-icon"
            @click.stop.prevent="saveObjects()"
          >
            <b-icon icon="save" size="is-small" class="has-text-light" />
          </a>-->
        </header>
        <div class="card-content table-card-content">
          <b-field
            position="is-right"
            class="filter-field"
            grouped
            group-multiline
          >
            <div class="no-padding filter2 filter-map-tanggal">
            <date-picker v-model="filterMap.tanggal"></date-picker>
            </div>
            <generic-filter-select
              v-if="isPSPD"
              class="filter2"
              apiPath="/matakuliah/options/"
              field="matakuliah"
              v-model="filterMap.matakuliah"
            ></generic-filter-select>
            <generic-filter-select
              v-else
              class="filter3"
              apiPath="/stase/options/"
              field="rotasi"
              v-model="filterMap.stase"
            ></generic-filter-select>
            <div class="no-padding filter2">
              <b-select v-model="filterMap.jenis" placeholder="Jenis">
                <option v-if="filterMap.jenis" class="nofilter" value>
                  Jenis
                </option>
                <option value="Online">Online</option>
                <option value="Offline">Offline</option>
              </b-select>
            </div>
            <!-- <verifikasi-select class="filter2" v-model="filterMap.verifikasi"></verifikasi-select> -->
            <cari-input class="filter3" v-model="filterMap.cari"></cari-input>
            <filter-button @apply-filter="applyFilter"></filter-button>
          </b-field>
          <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
          <div class="box-container">
            <small v-if="kegiatanList.length > 0" class="page-info is-pulled-right"
              ><strong>{{ pagination.startRowNum }}</strong> -
              <strong>{{
                pagination.startRowNum + kegiatanList.length - 1
              }}</strong>
              dari <strong>{{ pagination.total }}</strong></small
            >
            <div class="cleardiv"></div>
            <box
              v-for="kegiatan in kegiatanList"
              :key="kegiatan.id"
              :title="kegiatan.kegiatan"
              :subtitle1="`Jenis: ${kegiatan.jenis}`"
              :detailUrl="{
                name: 'kegiatan-mahasiswa-detail',
                params: { id: kegiatan.id },
              }"
            >
              <template v-slot:date>
                <span class="is-pulled-right has-text-grey">
                  <small class="tanggal">{{ kegiatan.tanggal }}</small>
                </span>
              </template>
              <template v-slot:content>
                <span v-if="!isPSPD">
                  {{ kegiatan.pspd }}
                  <br />
                </span>
                <div>
                  {{ kegiatan.mata_kuliah }}, {{ kegiatan.preceptor }}
                </div>
              </template>
            </box>
            <box v-if="kegiatanList.length == 0">
              <template v-slot:content>
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>Tidak ada data.</p>
                  </div>
                </section>
              </template>
            </box>
          </div>

          <b-pagination
            v-if="pagination.showPagination"
            :total="pagination.total"
            :per-page="pagination.limit"
            :current.sync="pagination.page"
            order="is-centered"
            icon-pack="fas"
            @change="pageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { clone, has } from "lodash";
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import paginationMixin from "@/apps/core/mixins/paginationMixin";
import KegiatanPSPDList from "../models/kegiatanPSPDList.js";

export default {
  name: "KegiatanPSPDList",
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
    CariInput: () => import("@/apps/core/components/CariInput.vue"),
    DatePicker: () => import("@/apps/core/components/DatePicker.vue"),
    FilterButton: () => import("@/apps/core/components/FilterButton.vue"),
    // HasilMark: () => import("@/apps/core/components/HasilMark.vue"),
    // VerifikasiSelect: () =>
    //   import("@/apps/aktivitas/components/VerifikasiSelect.vue"),
    GenericFilterSelect: () =>
      import("@/apps/core/components/GenericFilterSelect.vue"),
  },
  data() {
    this.kegiatanListMdl = new KegiatanPSPDList();
    this.listMdl = this.kegiatanListMdl; // alias untuk digunakan di paginationmixin
    let obv = this.kegiatanListMdl.getObservables();
    // obv.canVerifikasi = false;
    return obv;
  },
  computed: {
    ...mapState("accounts", ["userId"]),
    ...mapGetters("accounts", ["isPSPD", "isPreceptor"]),
    kegiatanLength() {
      return this.kegiatanList.length > 0;
    },
  },
  // methods: {
  // includes(id, type) {
  //   if (has(this.kegiatanBelumSet, id)) {
  //     if (type == "verifikasi")
  //       return this.kegiatanBelumSet[id][type] == "Belum";
  //     return this.kegiatanBelumSet[id][type];
  //   }
  //   return null;
  // },
  // needConfirmation() {
  //   if (!this.canVerifikasi) return this.canVerifikasi;
  //   let kegiatanBelumSet = this.kegiatanList.filter(
  //     (d) =>
  //       this.kegiatanBelumSet[d.id].verifikasi != d.verifikasi ||
  //       this.kegiatanBelumSet[d.id].nilai_sikap != d.nilai_sikap
  //   );
  //   return kegiatanBelumSet.length > 0;
  // },
  // filter() {
  //   this.kegiatanListMdl.applyFilter();
  //   this.canVerifikasi =
  //     this.isPreceptor && this.filterMap.verifikasi == "Belum";
  // },
  // applyFilter() {
  //   if (this.needConfirmation()) {
  //     this.showConfirmationDlg(() => {
  //       this.saveObjects(this.filter);
  //     });
  //   } else {
  //     this.filter();
  //   }
  // },
  // getType(idx) {
  //   if (has(this.errorList[idx], "nilai_sikap")) {
  //     return this.errorList[idx]["nilai_sikap"] ? "is-danger" : "";
  //   }
  //   return "";
  // },
  // validateInline(idx) {
  //   this.kegiatanListMdl.validateInline(idx);
  // },
  // },
  mixins: [onlineCheckMixin, paginationMixin],
  activated() {
    if (
      // has(this.$route.params, "verifikasi") ||
      has(this.$route.params, "stase")
    ) {
      this.kegiatanListMdl.reset();
      // this.filterMap.verifikasi = this.$route.params.verifikasi;
      this.filterMap.matakuliah = this.$route.params.matakuliah;
      this.filterMap.stase = this.$route.params.stase;
      this.filterMap.jenis = null;
      this.filterMap.cari = "";
      this.filterMap.tanggal = "";
      this.filterPrevMap = clone(this.filterMap);
    }
    // this.canVerifikasi =
    //   this.isPreceptor && this.filterMap.verifikasi == "Belum";
    this.fetchData();
  },
};
</script>


<style scoped lang="scss">
@import "@/apps/core/assets/scss/listview.scss";

.tanggal {
  font-size: 0.75rem !important;
}

::v-deep .is-grouped-multiline {
  margin-bottom: unset !important;
}

.small-icon {
  vertical-align: middle;
  padding-bottom: 5px;
}

.filter-map-tanggal {
  max-width: 200px;
}
</style>
